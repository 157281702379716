@font-face {
  font-family: Tasa;
  src: url(/src/fonts/TASAOrbiterDisplay-Medium-BF64891cba56e9b.otf);
}
@font-face {
  font-family: TasaLight;
  src: url(/src/fonts/TASAOrbiterDisplay-Regular-BF64891cba1d563.otf);
}
@font-face {
  font-family: TasaBold;
  src: url(/src/fonts/TASAOrbiterDisplay-Bold-BF64891cba5ae6f.otf);
}
body {
  margin: 0;
  font-family: "Tasa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
