@font-face {
  font-family: Tasa;
  src: url(/src/fonts/TASAOrbiterDisplay-Medium-BF64891cba56e9b.otf);
}
@font-face {
  font-family: TasaLight;
  src: url(/src/fonts/TASAOrbiterDisplay-Regular-BF64891cba1d563.otf);
}
@font-face {
  font-family: TasaBold;
  src: url(/src/fonts/TASAOrbiterDisplay-Bold-BF64891cba5ae6f.otf);
}

/*Global variables, as Brand Colors for web Color*/
:root {
  --mainColor: #ffffff;
  --mainColorLight: #000000;
  --secondaryColor: #bd2128;
  --textColor: #000000;
  --tertiaryColor: #acacac;
  --footerColor: #e6e6e6;
  --fontFamilyBold: TasaBold;
  --fontFamily: Tasa;
  --fontFamilyLight: TasaLight;
  font-family: "Tasa";
}

* h1 {
  font-family: var(--fontFamilyBold);
  font-size: 2rem;
}

* h2 {
  font-family: var(--fontFamily);
  font-size: 1.5rem;
}

* h3 {
  font-family: var(--fontFamilyLight);
  font-size: 1.5rem;
}

.nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  z-index: 1000;
  background-color: var(--mainColor);
  color: var(--textColor);
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.15),
    0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.nav-logo {
  height: 4rem;
  width: auto;
}

.nav-logo {
  background-color: none;
}

.nav-boxLinks {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 1rem;
}

.nav-links {
  background-color: var(--mainColor);
  color: var(--textColor);
  text-decoration: none;
}
.nav-links-active {
  color: var(--secondaryColor); /* Color para el enlace activo */
  text-decoration: none;
}

.nav-links:hover {
  color: var(--secondaryColor);
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 80px;
}

.filter-bar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  background-color: var(--mainColor);
  /* color de fondo */
  color: var(--textColor);
}

.filter-categories {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 1rem;
}

.filter-text {
  color: var(--tertiaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-category {
  color: var(--textColor);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-bottom 0.2s;
}

.filter-category:hover {
  color: var(--secondaryColor);
  border-bottom: 2px solid var(--secondaryColor);
  height: 100%;
  width: 100%;
}

.popper {
  display: flex;
  background-color: var(--mainColor);
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 300px;
  overflow-y: auto;
  gap: 3px;
  padding: 5px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 50px;
  z-index: 100;
}

.popper > span:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  cursor: pointer;
}

.popper > a:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  cursor: pointer;
}

.popper .subCategory > a:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  cursor: pointer;
}

.popper .subCategory > span:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  cursor: pointer;
}

.popper * {
  padding: 3px;
  border-radius: 8px;
}

.popper .subCategory {
  display: flex;
  flex-direction: column;
}

.popper .subCategory * {
  padding: 3px;
  border-radius: 8px;
}

.popper .subCategory span {
  font-family: var(--fontFamilyBold);
}

.popper .subCategory a {
  padding-left: 15px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spinner-llanta {
  width: 100px; /* Ajusta el tamaño según necesites */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bannerBienvenida {
  background-image: linear-gradient(
    0deg,
    rgb(155, 155, 155) 5%,
    rgb(201, 201, 201) 36%,
    rgba(255, 255, 255, 1) 100%
  );
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
}

.bannerBienvenida .quarter-circle-top-left {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px; /* Ajusta el tamaño del círculo */
  height: 250px;
  background-color: var(
    --secondaryColor
  ); /* Color de fondo del cuarto de círculo */
  clip-path: circle(50% at 100% 0%);
}

.bannerBienvenida .quarter-circle-bottom-right {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px; /* Ajusta el tamaño del círculo */
  height: 250px;
  background-color: var(
    --secondaryColor
  ); /* Color de fondo del cuarto de círculo */
  clip-path: circle(50% at 0% 100%);
}

.banner2 {
  justify-content: center;
}

.banner2 .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 4;
}

.banner2 .texto .renglon {
  color: var(--mainColor);
}
.banner2 .quarter-circle-top-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px; /* Ajusta el tamaño del círculo */
  height: 250px;
  background-color: white; /* Color de fondo del cuarto de círculo */
  clip-path: circle(50% at 0% 0%);
}

.banner2 .quarter-circle-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 250px; /* Ajusta el tamaño del círculo */
  height: 250px;
  background-color: white; /* Color de fondo del cuarto de círculo */
  clip-path: circle(50% at 100% 100%);
}

@media (max-width: 1100px) {
  .bannerBienvenida .efecto,
  .banner2 .efecto {
    min-width: 100%;
    min-height: 400px;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;
    position: absolute;
  }

  .banner2 {
    position: relative;
    justify-content: left;
  }

  .banner2 .texto {
    padding-left: 2rem;
    justify-content: left;
    align-items: flex-start;
  }

  .banner2 .texto .renglon {
    color: var(--textColor);
  }

  .banner2 img {
    position: absolute;
    right: 0;
  }
}

.gallery-brand {
  display: flex;
  justify-content: space-between; /* Asegura que los botones estén en los extremos */
  align-items: center;
  width: 100%; /* Ajusta al ancho disponible */
  padding-top: 1rem;
}

.gallery-brand button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.gallery-brand .image-container {
  display: flex;
  justify-content: center; /* Centra las imágenes */
  align-items: center;
  overflow: hidden; /* Esconde cualquier imagen que se extienda fuera del contenedor */
  width: calc(
    100% - 60px
  ); /* Calcula el ancho tomando en cuenta el espacio de los botones */
}

img.gallery {
  flex: 1; /* Permite que las imágenes crezcan para llenar el espacio disponible */
  object-fit: contain; /* Asegura que las imágenes mantengan su relación de aspecto sin recortarse */
  margin: 0 10px; /* Espacio horizontal entre imágenes */
  height: auto; /* Altura se ajusta automáticamente */
  max-width: 124px;
}

/* Media Queries para adaptabilidad */
@media (max-width: 595px) {
  img.gallery {
    max-width: 4rem; /* Tamaños más pequeños para dispositivos pequeños */
    max-height: 4rem;
  }
  .filter-bar {
    display: none;
  }
}

@media (max-width: 480px) {
  img.gallery {
    max-width: 3rem; /* Tamaños aún más pequeños para pantallas muy pequeñas */
    max-height: 3rem;
  }
}

.content .destacados {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  font-family: var(--fontFamilyBold);
  font-size: 2rem;
  padding: 1rem;
}

.content .destacados .cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 1rem;
  gap: 3rem;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  background-color: var(--footerColor);
  color: var(--textColor);
}

.footer .section,
.footer .section2 {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho definido */
}

.footer .section {
  flex-basis: calc(
    30% - 2rem
  ); /* El ancho máximo menos el padding horizontal */
  max-width: 30%;
  min-width: 200px;
}

.footer .section2 {
  flex-basis: calc(
    20% - 2rem
  ); /* El ancho máximo menos el padding horizontal */
  max-width: 20%;
  min-width: 200px;
}

.footer .section p,
.footer .section2 p {
  margin: 0;
  padding-bottom: 10px;
  font-family: var(--fontFamily);
  font-size: 16px;
}

.footer .form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer .form .button {
  width: max-content;
  text-transform: none;
}

.footer a img {
  margin: 5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.footer .section .redesSociales {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .section .redesSociales a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer .section .redesSociales .liga {
  display: flex;
  align-items: center;
  color: #275efe;
  gap: 5px;
}

.agregarCoche {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.agregarCoche form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.agregarCoche .textFields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.agregarCoche .textFields2 .detallesAdicionales {
  display: flex;
  flex-direction: column;
}
.agregarCoche .textFields2 .detallesAdicionales .subcampos {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.agregarCoche .textField {
  width: 100%;
  max-width: calc(33% - (2rem / 3));
}

.agregarCoche form .button {
  background-color: var(--mainColorLight);
  color: var(--mainColor);
}

.content .blogTestimonio {
  display: flex;
  padding: 1rem 3rem 1rem 3rem;
  gap: 1rem;
  width: 100%;
}

.content .blogTestimonio .blog {
  display: flex;
  padding: 5px;
  gap: 1rem;
  width: calc((100% / 3) * 2);
}

.content .blogTestimonio .testimonio {
  display: flex;
  padding: 5px;
  gap: 1rem;
  width: calc((100% / 3));
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 1rem 4rem;
  background-image: linear-gradient(
    90deg,
    rgba(189, 33, 40, 1) 36%,
    rgba(205, 86, 92, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  );
}

.search-input {
  background-color: var(--mainColor);
  width: 100%; /* Ajusta el ancho según tus necesidades */
  border-radius: 4px;
}

.carGallery {
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 0.5rem 2rem;
}

.carGallery .barraSuperior {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 5px;
  margin-bottom: 20px;
}

.carGallery .container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  gap: 3rem;
}

.carGallery .card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.carGallery .cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  max-width: 100%;
}

.carGallery .navPages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.carGallery .filter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.contacto .content {
  display: flex;
  width: auto;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
  padding-top: calc(80px + 3rem);
  gap: 3rem;
}

.contacto .content .columns {
  display: flex;
  flex-wrap: wrap; /* Asegura que los elementos puedan pasar a la siguiente línea */
  justify-content: space-around;
  gap: 4rem;
}

.contacto .formulario {
  flex-grow: 1; /* Permite que el formulario crezca para llenar el espacio */
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contacto form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 2rem;
}

.contacto form .textField {
  width: 100%;
}

.contacto .content .columns .banner {
  width: 600px; /* Establece el ancho del banner */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (max-width: 600px) {
  /* Ajusta este valor según sea necesario */
  .contacto .content .columns {
    flex-direction: column; /* Cambia la dirección a columna para apilar los elementos verticalmente */
  }

  .contacto .content .columns .formulario,
  .contacto .content .columns .banner {
    width: 100%; /* Establece el ancho al 100% para ocupar todo el contenedor */
    max-width: none; /* Anula cualquier max-width previo */
  }
}

.contacto .banner img {
  width: 100%;
  max-height: auto;
}

.contacto .banner .botones {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.contacto Button {
  text-transform: none;
}

.contacto .banner Button:hover {
  border-color: var(--secondaryColor);
  color: var(--secondaryColor);
}

.contacto .texto {
  color: #333333;
}

.rojo {
  color: var(--secondaryColor);
}

.RangeSelectorContainer {
  cursor: default;
  --color-primary: #275efe;
  --color-headline: #3f4656;
  --color-text: #99a3ba;
}

.card-content {
  width: 100%;
  max-width: 312px;
  padding: 8px 8px 8px 32px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(18, 22, 33, 0.12);
}

.card-content .values div,
.card-content .current-range div {
  display: inline-block;
  vertical-align: top;
}

.card-content .values {
  margin: 0;
  font-weight: 500;
  color: var(--secondaryColor);
}

.card-content .values > div:first-child {
  margin-right: 2px;
}

.card-content .values > div:last-child {
  margin-left: 2px;
}

.card-content .current-range {
  display: block;
  color: var(--color-text);
  margin-top: 8px;
  font-size: 14px;
}

.card-content .slider {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.6em;
  color: var(--color-text);
}

/* Range Slider */
.input-ranges[type="range"] {
  width: 210px;
  height: 30px;
  overflow: hidden;
  outline: none;
}

.input-ranges[type="range"],
.input-ranges[type="range"]::-webkit-slider-runnable-track,
.input-ranges[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: none;
}

.input-ranges[type="range"]::-webkit-slider-runnable-track {
  width: 200px;
  height: 1px;
  background: var(--color-headline);
}

.input-ranges[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
  background: none;
}

.input-ranges[type="range"]::-webkit-slider-thumb {
  position: relative;
  height: 15px;
  width: 15px;
  margin-top: -7px;
  background: #fff;
  border: 1px solid var(--color-headline);
  border-radius: 25px;
  cursor: pointer;
  z-index: 1;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.input-ranges[type="range"]::-webkit-slider-thumb:hover {
  background: #eaefff;
  border: 1px solid var(--color-primary);
  outline: 0.5px solid var(--color-primary);
}

.input-ranges[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.input-ranges[type="range"]:nth-child(1)::-webkit-slider-thumb {
  z-index: 2;
}

.rangeslider {
  font-size: 14px;
  position: relative;
  height: 20px;
  width: 210px;
  display: inline-block;
  margin-top: -5px;
}

.rangeslider input {
  position: absolute;
}

.rangeslider span {
  position: absolute;
  margin-top: 20px;
  left: 0;
}

.rangeslider .right {
  position: relative;
  float: right;
  margin-right: -5px;
}

.carIdSpecifics {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 2rem;
}

.carIdSpecifics .redireccion {
  display: flex;
  flex-wrap: wrap;
  width: auto;
}
.carIdSpecifics .column {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem; /* Ajusta el espacio entre los contenedores */
}

.carIdSpecifics .column .left {
  flex: 1; /* Hace que el contenedor izquierdo ocupe todo el espacio restante */
  min-width: 250px; /* Evita que el contenedor izquierdo se encoge menos de su contenido */
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.carIdSpecifics .column .left .carousel {
  display: flex;
  max-height: auto;
  width: 100%;
}

.left .informacionBasica {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 1rem;
}

.left .informacionBasica .infoCarBoxes {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
}

.left .informacionBasica .infoCarBox {
  display: flex;
  flex-direction: column;
  border: var(--tertiaryColor) solid 2px;
  border-radius: 8px;
  padding: 0.5rem;
  width: calc(33.3% - 2rem);
  min-width: 175px;
}

.left .detallesAdicionales {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 1rem;
}
.left .detallesAdicionales .carBoxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.left .detallesAdicionales .infoCarBoxes {
  display: flex;
  flex-direction: column;
  border: var(--tertiaryColor) solid 2px;
  border-radius: 8px;
  padding: 0.5rem;
  width: calc(33.3% - 2rem);
  min-width: 175px;
}

.left .detallesAdicionales .infoCarBoxes .category {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
}

.category .icon {
  color: var(--secondaryColor);
}

.carIdSpecifics .column .left .active-thumbnail {
  border-radius: 10px;
  border: none;
}

.carIdSpecifics .column .left .thumbnail {
  border-radius: 10px;
}

.carousel-container .fade {
  -webkit-animation-name: none;
  -webkit-animation-duration: 1.5s;
  animation-name: none;
  animation-duration: 1.5s;
}

.carIdSpecifics .column .right {
  flex-basis: 300px; /* Establece un ancho base para el contenedor derecho */
  flex-shrink: 0; /* Evita que el contenedor derecho se encoge */
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.carIdSpecifics .column .right .carInfo {
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 0.5rem;
  padding-right: 2rem;
  top: 200px;
  position: fixed;
}

.carIdSpecifics .column .left .carInfo {
  display: none;
}

@media (max-width: 900px) {
  .carIdSpecifics .column .right {
    display: none;
  }
  .carIdSpecifics .column .left .carInfo {
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 0.5rem;
  }
}

.carIdSpecifics .column .carInfo .titulo {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.carIdSpecifics .carrosSimilares {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.carIdSpecifics .carrosSimilares .cards {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.carIdSpecifics .carrosSimilares .cards carCard {
  min-width: 300px;
}

@media (max-width: 595px) {
  .carGallery .container {
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
  }

  .banner-Bienvenida .titulo,
  .subtitulo,
  .button1,
  .button2 {
    display: flex;
    color: var(--mainColor);
    width: 100%;
    height: auto;
    align-items: center;
    font-size: 1.5rem;
  }

  .banner-Bienvenida {
    height: fit-content;
    min-height: 25rem;
  }
}

div.slick-initialized.slick-slider {
  position: relative !important;
}

.ubicacion .info .texto {
  display: flex;
  height: 100%;
  flex: 1;
  min-width: 200px /* Tamaño mínimo */;
  max-width: 300px;
  min-height: 400px;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  gap: 1rem;
  flex-direction: column;
}

.ubicacion iframe {
  border-radius: 8px;
  border: none;
  flex: 1;
  min-width: 400px /* Tamaño mínimo */;
  height: auto;
  min-height: 400px;
}

.ubicacion .cards {
  background-color: #e6e6e6;
  height: auto;
  width: 100%;
  min-width: auto;
  border-radius: 0px 12px 12px 12px;
}

.ubicacion .cards .info {
  display: flex;
  padding: 1rem;
  height: auto;
  width: auto;
  gap: 1rem;
  flex-wrap: wrap;
}

.ubicacion .ubicacionesHorizontal {
  display: none;
}

.ubicacion .movil {
  display: flex;
}

/* @media query para ajustar max-width basado en el tamaño del viewport */
@media (max-width: 980px) {
  /* Ajusta este valor según el punto en el que los elementos se reorganizan */
  .ubicacion .info .texto {
    max-width: 100%; /* Permite que el texto ocupe el 100% cuando el iframe está debajo */
  }
}

@media (max-width: 700px) {
  .ubicacion .movil {
    display: flex;
    flex-direction: column;
  }

  .ubicacion .cards {
    background-color: #e6e6e6;
    height: auto;
    width: 100%;
    min-width: auto;
    border-radius: 0px 0px 12px 12px;
  }

  .ubicacion .cards .info {
    flex-direction: column;
  }
  .ubicacion .ubicacionesVertical {
    display: none;
  }
  .ubicacion .ubicacionesHorizontal {
    display: flex;
  }
  .ubicacion iframe {
    min-width: 100px;
    width: 100%;
  }
}
